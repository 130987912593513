.react-daterange-picker__wrapper {
    border: none !important;
}

.react-calendar__month-view__weekdays__weekday {
    color: rgb(29, 78, 216);
}

abbr[title]{
    text-decoration: none !important;
}

.react-calendar__month-view__days__day--weekend{ 
    color: rgb(15, 51, 153);
}

.react-calendar {
    border-radius: 3px;
    border: 1px solid rgb(229, 231, 235);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.react-calendar__tile--now {
    background-color: rgba(59, 130, 246, 0.5) !important;
}